import { AddUser } from '@myneva-portals/projects/src/components';
import { IUser, RestUsersService } from '@myneva-portals/services/src/services';
import { navigate } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { navigationSelector } from '../../state/reducers/navigation';
import breadcrumbsUtils from '@myneva-portals/utils/src/utils/breadcrumbs-utils';
import { formErrorToast } from '../../components/sections/static-data/default-error-toast';
import { EFormValidation } from 'front-components/src/constants';
import { showToast } from 'front-components/src/components';

export const AddCoreAdminContent: React.FC = () => {
  const { t } = useTranslation();
  const formToastConfig = formErrorToast();

  const url = process.env.WEB_GATEWAY_URL + '/core/';
  const userService = new RestUsersService(url);

  const { breadcrumbs } = useSelector(navigationSelector);

  const customBreadCrumbConfig = [
    {
      breadcrumbName: t('ADD_CORE_ADMIN_BREADCRUMB'),
      path: '/users/add-core-admin',
    },
  ];

  let localErrors = [];

  const handleSave = async (user: IUser, setError) => {
    try {
      await userService.addUser(user);
      navigate('/users');
    } catch (e) {
      if (e.response.status == 400) {
        e?.response.data.errors.map((item) => {
          const fieldName =
            item.fieldName && item.fieldName.replaceAll('_', '');
          localErrors.push(EFormValidation.DUPLICATE + fieldName);
          setError(fieldName, EFormValidation.DUPLICATE + fieldName);
        });
        showToast(formToastConfig, localErrors);
        localErrors = [];
      }
    }
  };

  const handleExit = () => {
    navigate('/users');
  };

  return (
    <AddUser
      translation={t}
      header={t('ADD_USER_HEADER')}
      toastConfig={formToastConfig}
      formikError={localErrors}
      onExit={handleExit}
      onSave={handleSave}
      breadcrumbs={breadcrumbsUtils.getBreadcrumbs(
        breadcrumbs,
        customBreadCrumbConfig
      )}
      addNoteLabel={t('ADD_NOTE')}
      removeNoteText={t('REMOVE_NOTE')}
    />
  );
};
