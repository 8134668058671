import { PortalLayout } from 'front-components/src/components';
import { AdminMenu } from '../../components/asside-menu/asside-menu';
import { PortalHeader } from '../../components/portal-header/portal-header';
import * as React from 'react';
import { AddCoreAdminContent } from '../../content/admins/add-core-admin';

const AddCoreAdminPage = () => {
  const asside = <AdminMenu />;
  const content = <AddCoreAdminContent />;

  return (
    <main>
      <title>Add core admin</title>
      <PortalLayout header={<PortalHeader />} asside={asside}>
        {content}
      </PortalLayout>
    </main>
  );
};

export default AddCoreAdminPage;
